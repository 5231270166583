import React, { Component } from "react";
import PropTypes from "prop-types";
import DashboardApi from "../utils/api/dashboardApi";

export class CalculatingQuoteComponent extends Component {
  ga = null;

  loadingStyle = { width: "100%" };
  constructor(props) {
    super(props);
    const loader = props.loadingGif || "standard";

    let limage = "analyizing-loader.gif";

    switch (loader) {
      case "standard":
        limage = "analyizing-loader.gif";
        break;
      case "no-soil":
        limage = "analyizing-loader-no-soil.gif";
        this.loadingStyle = { maxHeight: 220 };
        break;
      case "pest-control":
        limage = "pest-control-loader.gif";
        break;
      default:
    }

    this.state = {
      loaderTimeoutFinished: false,
      callbackFinished: false,
      loaderImage: limage,
    };
  }

  componentDidMount() {
    const { triggerNextStep } = this.props;
    this.getQuotePriceBlocks();
    setTimeout(() => {
      triggerNextStep({ value: "Opening Quote Now" });
    }, 7500);
  }

  async getQuotePriceBlocks() {
    const {
      selectedServices,
      answers,
      companyId,
      botId,
      adjustedLotSize,
      chatId,
      customerZip,
      onboarding,
      preview,
    } = this.props;

    const payload = {
      serviceTypes: selectedServices,
      companyId,
      quoteId: chatId,
      botId,
      estimate: adjustedLotSize,
      answers,
      postalCode: customerZip,
    };

    try {
      const response = await DashboardApi.post(
        `/v1/rtp/quote/services${
          onboarding ? "?onboarding=true" : preview ? "?preview=true" : ""
        }`,
        payload,
      );
      const { data } = response;
      if (this.props.ga) {
        this.props.ga.send("event", "quote", "given", 1);
      }

      const { setPriceBlocks } = this.props;
      const ontop = data.filter((i) => i.alwaysOnTop);
      const notOnTop = data.filter((i) => !i.alwaysOnTop);
      const all = [...ontop, ...notOnTop];
      setPriceBlocks(all);
    } catch (error) {
      if (error?.response?.status === 404) {
        this.props.bot.moveToStep("sqft-too-big");
      } else {
        console.log(error);
      }
    }
  }

  render() {
    return (
      <div
        style={{
          width: "80%",
          fontFamily: "'Open Sans', sans-serif",
          fontSize: 16,
          margin: "auto",
          paddingBottom: 50,
          textAlign: "center",
          minHeight: 200,
          color: "rgb(20, 126, 251)",
        }}
      >
        <img
          src={`/assets/images/${this.state.loaderImage}`}
          alt="calculating"
          style={this.loadingStyle}
        />
      </div>
    );
  }
}

CalculatingQuoteComponent.propTypes = {
  setPriceBlocks: PropTypes.func,
};
