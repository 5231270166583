import DashboardApi from "../utils/api/dashboardApi";

export const saveOrder = async (
  companyId,
  botId,
  clientInfo,
  question,
  answers,
) => {
  const { client_id, contact_id } = clientInfo;

  const keys = Object.keys(answers);
  const notes = keys
    .filter((x) => x !== "Greeting.Selection" && x !== ";")
    .map((key) => {
      return `${key}: ${answers[key]}`;
    })
    .join("\r\n");

  const requestPayload = {
    companyId: companyId,
    botId: botId,
    client_id,
    contact_id,
    question_id: question.id,
    notes: [notes],
  };

  try {
    const response = await DashboardApi.post(
      "/v1/servman/order",
      requestPayload,
    );

    if (response.status !== 200) {
      console.log(response.data);
    }
  } catch (error) {
    console.log(error);
  }
};
