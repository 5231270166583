// NOTE: This implementation is based off parse-address-string which was already in use in the project.
import { STATES } from "../constants/locations";
import { COUNTRIES } from "../constants/locations";

const looksLikeCountry = (strValue) => {
  const countries = {};
  for (const k in COUNTRIES) {
    if (Object.prototype.hasOwnProperty.call(COUNTRIES, k)) {
      countries[k.toLowerCase()] = true;
      countries[COUNTRIES[k].toLowerCase()] = true;
    }
  }
  const str = strValue.trim().toLowerCase();
  if (str === "usa") {
    return true;
  }
  return !!countries[str];
};

const looksLikeState = (strValue) => {
  const states = {};
  for (const k in STATES) {
    if (Object.prototype.hasOwnProperty.call(STATES, k)) {
      states[k.toLowerCase()] = true;
      states[STATES[k].toLowerCase()] = true;
    }
  }
  const str = strValue.trim().toLowerCase();
  return !!states[str];
};

const parseAddress = (addressInput) => {
  let singleLineAddress = addressInput.trim();
  // Initialize the address object
  const addressObj = {
    street_address1: null,
    city: null,
    state: null,
    postal_code: null,
    country: null,
  };

  // Check if the input is a valid string
  if (typeof singleLineAddress !== "string" || !singleLineAddress) {
    return addressObj;
  }

  // Extract postal code
  let postalCode = singleLineAddress.match(
    /([0-9]{5})|([a-z][0-9][a-z] ?[0-9][a-z][0-9])/gi,
  );
  let indexOfPostalCode = -1;

  if (postalCode) {
    postalCode = postalCode.pop(); // Pick the match closest to the end
    indexOfPostalCode = singleLineAddress.lastIndexOf(postalCode);

    // Check if postal code is part of street address
    if (indexOfPostalCode === 0 && singleLineAddress.length > 10) {
      postalCode = null;
      indexOfPostalCode = -1;
    }

    if (postalCode) {
      addressObj.postal_code = postalCode;

      const everythingAfterPostalCode = singleLineAddress.substr(
        indexOfPostalCode + postalCode.length,
      );
      singleLineAddress =
        singleLineAddress.substr(0, indexOfPostalCode) +
        everythingAfterPostalCode;

      // Try to extract country
      const possibleCountry = everythingAfterPostalCode
        .replace(/\s*,/, "")
        .split(",")
        .shift()
        .trim();
      if (possibleCountry && looksLikeCountry(possibleCountry)) {
        addressObj.country = possibleCountry;
        singleLineAddress = singleLineAddress.substr(0, indexOfPostalCode); // Discard everything after postal + country
      }
    }
  }

  const addySplit = singleLineAddress.split(",");

  // Handle special case: Neighborhood, City, State
  if (addySplit.length === 3 && looksLikeState(addySplit[2])) {
    addressObj.street_address1 = addySplit[0].trim();
    addressObj.city = addySplit[1].trim();
    addressObj.state = addySplit[2].trim();
    return addressObj;
  }

  // Handle the generic case
  for (const addyPartRaw of addySplit) {
    const addyPart = addyPartRaw.trim();
    if (!addyPart) continue;

    // If it has numbers, assume it's a street address
    if (/[0-9]/.test(addyPart) && !addressObj.street_address1) {
      addressObj.street_address1 = addyPart;
    }
    // If it looks like a state
    else if (looksLikeState(addyPart) && !addressObj.state) {
      addressObj.state = addyPart;
    }
    // If it looks like a country
    else if (looksLikeCountry(addyPart) && !addressObj.country) {
      addressObj.country = addyPart;
    }
    // Otherwise, assume it's a city
    else if (!addressObj.city) {
      addressObj.city = addyPart;
    }
  }

  return addressObj;
};

export default parseAddress;
