export const PROGRAM_TYPES = {
  IRRIGATION_SPRING_STARTUP_PROGRAM: "Irrigation Spring Startup Program",
  YARD_ARMOUR: "Yard Armour",
  MOLE_CONTROL: "Mole Control",
  LAWN_CARE: "Lawn Care",
  LAWN_TREATMENT_PLAN: "Lawn Treatment Plan",
  WATER_SAVER: "Water Saver",
  FLEA_ANT: "Flea & Ant",
  RODENT: "Rodent",
  HOME_INSPECTION: "Home Inspection",
  TICK: "Tick",
  IRRIGATION_SERVICES: "Irrigation Services",
  NATURAL_LAWN_CARE: "Natural Lawn Care",
  FIRE_ANT: "Fire Ant",
  PERIMETER_PEST_CONTROL: "Perimeter Pest Control",
  COCKROACH: "Cockroach",
  LAWN_CARE_SERVICES: "Lawn Care Services",
  BEDBUGS: "Bedbugs",
  SPOTTED_LANTERN_FLY: "Spotted Lantern Fly",
  MOWING: "Mowing",
  SPRINKLER_SERVICES_PROGRAM: "Sprinkler Services Program",
  FLEA_TICK: "Flea & Tick",
  GEESE_REPELLENT_PROGRAM: "Geese Repellent Program",
  PLUMBING: "Plumbing",
  BIN_CLEANING: "Bin Cleaning",
  TREE_SHRUB_CARE: "Tree & Shrub Care",
  LAWN_PEST: "Lawn Pest",
  ORGANIC_LAWN_CARE: "Organic Lawn Care",
  PREFERRED_LAWN_PROGRAM: "Preferred Lawn Program",
  THATCH: "Thatch",
  AERATION: "Aeration",
  MOSQUITO_CONTROL: "Mosquito Control",
  COVID: "Covid",
  HVAC: "HVAC",
  SNOW_REMOVAL: "Snow Removal",
  YARD_CLEAN_UP: "Yard Clean Up",
  GRUB_CONTROL: "Grub Control",
  ARMY_WORM: "Army Worm",
  LIQUID_AERATION: "Liquid Aeration",
  PET_WASTE: "Pet Waste",
  AIR_8: "Air 8",
  PEST_CONTROL: "Pest Control",
  AERATION_OVERSEED: "Aeration & Overseed",
  MOSQUITO_TICK: "Mosquito & Tick",
  LAWN_SEEDING: "Lawn Seeding",
  IRRIGATION_WINTERIZATION_PROGRAM: "Irrigation Winterization Program",
  FERTILIZER_WEED_CONTROL: "Fertilizer & Weed Control",
  DEAD_ANIMAL: "Dead Animal",
  CHINCH_CONTROL: "Chinch Control",
  COMMERCIAL_CLEANING: "Commercial Cleaning",
  RESIDENTIAL_CLEANING: "Residential Cleaning",
  LAWN_TREATMENT_PACKAGES: "Lawn Treatment Packages",
};

export const PROGRAM_TYPE_LIST = Object.values(PROGRAM_TYPES);
