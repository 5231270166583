import React, { Component } from "react";

export class HtmlMessageComponent extends Component {
  constructor(props) {
    super(props);

    const key = "html-item-" + Math.floor(Math.random() * 1000000);
    this.state = {
      html: "",
      key,
    };
  }

  componentDidMount() {
    const { step } = this.props;
    const { inputAttributes } = step;
    let tHtml = inputAttributes.html;

    if (typeof tHtml === "function") {
      tHtml = tHtml();
    }

    this.setState({ html: tHtml });
  }

  render() {
    return (
      <p
        key={this.state.key}
        dangerouslySetInnerHTML={{ __html: this.state.html }}
      ></p>
    );
  }
}
