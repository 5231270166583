import React from "react";

import RatingWidget from "../components/widgets/rating.widget.component";

export function generateLawnServiceSteps(self) {
  return [
    // Mowing Service
    {
      name: "My Fabulous Mowing Service",
      type: "mowing",
      steps: [
        {
          id: "mowing-entry",
          message: `Lets find out more about your lawn...`,
          trigger: "ask-last-mowed",
        },
        {
          id: "ask-last-mowed",
          message: `When was the last time the lawn was mowed?`,
          trigger: "get-last-mowed",
        },
        {
          id: "last-mowed-thanks",
          message: `Great that's helpful for our team to know what to expect before our 1st visit.`,
          trigger: "ask-whos-mowing",
        },
        {
          id: "mowing-welcome-to-neighborhood",
          message: `Welcome to the neighborhood! New homeowners love our easy set it and forget it service.`,
          trigger: "mowing-current-happiness",
        },
        {
          id: "ask-whos-mowing",
          message: `Have you been mowing the lawn yourself or having another company take care of the lawn?`,
          trigger: "get-whos-mowing",
        },
        {
          id: "mowing-confirmation-upkeep-provider",
          message: `That's awesome! I am glad to hear you are taking pride in the yard. Believe it or not, not everybody cares about their yard as you do.`,
          trigger: "mowing-problems-with-provider",
        },
        {
          id: "mowing-problems-with-provider",
          message:
            "What are you looking for that your current company isn't providing?",
          trigger: "mowing-service-provider-missing",
        },
        {
          id: "mowing-provider-frustrating",
          message: `You have no idea how much we hear that. Believe me I know exactly how frustrating it can be to find a quality company.`,
          trigger: "mowing-current-happiness",
        },
        {
          id: "mowing-confirmation-upkeep",
          message: `That's awesome, I am glad to hear you are taking pride in the yard. Believe it or not, not everybody cares about their yard like you do.`,
          trigger: "mowing-current-happiness",
        },
        {
          id: "mowing-current-happiness",
          message: "What is your current happines with the lawn?",
          trigger: "get-mowing-current-happiness",
        },
        {
          id: "mowing-10-lawn",
          message:
            "Great we will help keep the lawn looking beautiful for you this season.",
          trigger: "next-service",
        },
        {
          id: "mowing-9orless-lawn",
          message: () => {
            return `Great ${self.state.customerName.trim().split(" ")[0]}, we will not stop working until we get your lawn to a 5 out of a 5!`;
          },
          trigger: "next-service",
        },
        { id: "thanksman", message: `reached the end`, end: true },
      ],
      probing_questions: [
        {
          id: "get-current-height",
          options: [
            '5" inches or less',
            '7" or less',
            '12" or less',
            'Higher than 12"',
          ],
          optionType: "default",
          trigger: "height-we-can-do-it",
        },
        {
          id: "get-last-mowed",
          options: [
            { value: "2 weeks ago", label: "2 weeks ago", hideText: true },
            { value: "4 weeks ago", label: "4 weeks ago", hideText: true },
            { value: "1 month ago +", label: "1 month ago +", hideText: true },
            { value: "Not sure", label: "Not sure", hideText: true },
          ],
          optionType: "default",
          trigger: "last-mowed-thanks",
        },
        {
          id: "get-whos-mowing",
          options: [
            {
              value: "I do it myself",
              label: "I do it myself",
              iconClass: ["fas", "user-alt"],
              color: "yellow",
            },
            {
              value: "I use another company",
              label: "I use another company",
              iconClass: ["fas", "cog"],
              color: "yellow",
            },
            {
              value: "I just moved in",
              label: "I just moved in",
              iconClass: ["fas", "people-carry"],
              color: "yellow",
            },
          ],
          optionType: "icon-text-option",
          conditionals: [
            {
              matches: "I do it myself",
              trigger: "mowing-confirmation-upkeep",
            },
            {
              matches: "I use another company",
              trigger: "mowing-confirmation-upkeep-provider",
            },
            {
              matches: "I just moved in",
              trigger: "mowing-welcome-to-neighborhood",
            },
          ],
        },
        {
          id: "mowing-service-provider-missing",
          options: [
            {
              value: "Customer Service",
              label: "Customer Service",
              hideText: true,
            },
            {
              value: "Better Quality",
              label: "Better Quality",
              hideText: true,
            },
            {
              value: "Better Pricing",
              label: "Better Pricing",
              hideText: true,
            },
          ],
          optionType: "default",
          trigger: "mowing-provider-frustrating",
        },
        {
          id: "get-mowing-current-happiness",
          component: <RatingWidget placeholderRating={3} />,
          waitAction: true,
          trigger: (payload) => {
            const val = payload.value;
            console.log("rating happiness", val);
            if (val !== "5") {
              return "9orless-lawn";
            } else {
              return "10-lawn";
            }
          },
        },
      ],
    },

    // Fert and Weed Service

    {
      name: "My Fabulous Fertilizer and WeedService",
      type: "fertnweed",
      steps: [
        {
          id: "fertnweed-entry",
          message: `Have you been doing the lawn yourself or having another company take care of the lawn?`,
          trigger: "get-fertnweed-options",
        },
        {
          id: "confirmation-upkeep",
          message: `That's awesome! I am glad to hear you are taking pride in the yard. Believe it or not, not everybody cares about their yard as you do.`,
          trigger: "current-happiness",
        },
        {
          id: "welcome-to-neighborhood",
          message: `Welcome to the neighborhood! New homeowners love our easy set it and forget it service.`,
          trigger: "current-happiness",
        },
        {
          id: "provider-upkeep-acknowledge",
          message: `That's awesome, I am glad to hear you are taking pride in the yard. Believe it or not, not everybody cares about their yard like you do.`,
          trigger: "problems-with-provider",
        },
        {
          id: "problems-with-provider",
          message:
            "What are you looking for that your current company isn't providing?",
          trigger: "service-provider-missing",
        },
        {
          id: "thanksman",
          message: `reached the end`,
          trigger: "next-service",
        },
        {
          id: "provider-frustrating",
          message: `You have no idea how much we hear that. Believe me I know exactly how frustrating it can be to find a quality company.`,
          trigger: "current-happiness",
        },
        {
          id: "current-happiness",
          message: "What is your current happiness with the lawn?",
          trigger: "get-current-happiness",
        },
        {
          id: "10-lawn",
          message:
            "Great we will help keep the lawn looking beautiful for you this season.",
          trigger: "lawn-thickness-ask",
        },
        {
          id: "9orless-lawn",
          message: () => {
            return `Great ${self.state.customerName.trim().split(" ")[0]}, we will not stop working until we get your lawn to a 5 out of a 5!`;
          },
          trigger: "lawn-thickness-ask",
        },
        {
          id: "lawn-thickness-ask",
          message: "What is the current thickness of the lawn?",
          trigger: "get-lawn-thickness",
        },
        {
          id: "lawn-thickness-response",
          message:
            "Wonderful! Your program will be specially blended based on your selection!",
          trigger: "biggest-issue-ask",
        },
        {
          id: "biggest-issue-ask",
          message: "What's the biggest issue you are having with the lawn?",
          trigger: "get-biggest-issue",
        },
        {
          id: "lawn-type-ask",
          message: "What kind of grass type do you have in your lawn?",
          trigger: "get-lawn-type",
        },
      ],
      probing_questions: [
        /// question 1
        {
          id: "get-fertnweed-options",
          setVariable: "lawnCareParty",
          options: [
            {
              value: "I do it myself",
              label: "I do it myself",
              iconClass: ["fas", "user-alt"],
              color: "yellow",
            },
            {
              value: "I use another company",
              label: "I use another company",
              iconClass: ["fas", "cog"],
              color: "yellow",
            },
            {
              value: "I just moved in",
              label: "I just moved in",
              iconClass: ["fas", "people-carry"],
              color: "yellow",
            },
          ],
          optionType: "icon-text-option",
          fillOptions: true,
          conditionals: [
            { matches: "I do it myself", trigger: "confirmation-upkeep" },
            {
              matches: "I use another company",
              trigger: "provider-upkeep-acknowledge",
            },
            { matches: "I just moved in", trigger: "welcome-to-neighborhood" },
          ],
        },
        //// question 2
        {
          id: "get-current-happiness",
          component: <RatingWidget placeholderRating={3} />,
          waitAction: true,
          setVariable: "lawnHappiness",
          trigger: "9orless-lawn",
        },

        /// question 3
        {
          id: "service-provider-missing",
          options: [
            {
              value: "Customer Service",
              label: "Customer Service",
              hideText: true,
            },
            {
              value: "Better Quality",
              label: "Better Quality",
              hideText: true,
            },
            {
              value: "Better Pricing",
              label: "Better Pricing",
              hideText: true,
            },
          ],
          optionType: "default",
          setVariable: "whyNewProvider",
          trigger: "provider-frustrating",
        },
        /// question 4
        {
          id: "get-lawn-thickness",
          options: [
            {
              value: "Thick",
              label: "Thick",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-thick.jpg",
            },
            {
              value: "Patchy",
              label: "Patchy",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-patchy.jpg",
            },
            {
              value: "Thin",
              label: "Thin",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-thin.jpg",
            },
          ],
          setVariable: "lawnThickness",
          optionType: "image-text-option",
          trigger: "lawn-thickness-response",
        },
        /// question 5
        {
          id: "get-biggest-issue",
          options: [
            {
              value: "Broadleaf weeds",
              label: "Broadleaf weeds",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-broadleaf-weeds.jpg",
            },
            {
              value: "Stubborn Weeds",
              label: "Stubborn Weeds",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-stubborn-weeds.jpg",
            },
            {
              value: "Grubs",
              label: "Grubs",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-grubs.jpg",
            },
            {
              value: "Crabgrass",
              label: "Crabgrass",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-crabgrass.jpg",
            },
            {
              value: "Disease",
              label: "Disease",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-disease.jpg",
            },
            {
              value: "Shade",
              label: "Shade",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-shade.jpg",
            },
          ],
          setVariable: "lawnBiggestProblem",
          optionType: "image-text-option",
          fillOptions: true,
          trigger: "next-service",
        },
        /// question 6
        {
          id: "get-lawn-type",
          options: [
            {
              value: "Bermuda Grass",
              label: "Bermuda Grass",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-bermuda-grass.jpg",
            },
            {
              value: "Zoysiagrass",
              label: "Zoysiagrass",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-zoysia-grass.jpg",
            },
            {
              value: "Tall fescue",
              label: "Tall fescue",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-tall-fescue.jpg",
            },
            {
              value: "St augistine",
              label: "St augistine",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/lawn-st-augistine.jpg",
            },
            {
              value: "I don't know",
              label: "I don't know",
              image:
                "https://lawnbot-assets.s3.us-east-2.amazonaws.com/I-dont-know.jpg",
            },
          ],
          setVariable: "lawnType",
          optionType: "image-text-option",
          fillOptions: true,
          trigger: "next-service",
        },
      ],
    },

    //  Irrigation Service //

    {
      name: "My Fabulous Lawn Seeding Service",
      type: "lawn-seeding",
      steps: [
        {
          id: "lawn-seeding-entry",
          message: "probing questions for lawn seeding",
          trigger: "next-service",
        },
      ],
      probing_questions: [],
    },
  ];
}
