import React, { useState } from "react";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 300 + theme.spacing(3) * 2,
      fontFamily: `'Open Sans', sans-serif`,
      marginBottom: 20,
    },
    margin: {
      height: theme.spacing(3),
    },
  }),
);

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function SqFtSlider(props) {
  const classes = useStyles();
  const initLotSize = props.totalLotSize();
  const [value, setValue] = useState(50);
  const [lotSize, setLotSize] = useState(props.adjustedLotSize());

  const updateLotSize = (e) => {
    const newval = Number.parseFloat(e.target.innerText);
    setValue(newval);
    const newLotSize = Number.parseInt(initLotSize * (newval / 100));
    setLotSize(newLotSize);
  };

  const submitLotUpdate = (e) => {
    const { triggerNextStep } = props;

    triggerNextStep({
      value: lotSize.toLocaleString() + " sq ft",
      sendUserMessage: true,
    });
  };
  return (
    <div className={classes.root}>
      <div style={{ width: "100%", minHeight: 170 }}>
        <img alt="top" src={props.image()} width="100%" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "flex-start",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography gutterBottom>
          TOTAL LOT SIZE: {initLotSize.toLocaleString()} sq ft
        </Typography>
        <img
          src="/assets/images/zillow-logo.png"
          alt="zillow"
          style={{ width: 83 }}
        />
      </div>

      <Typography gutterBottom>
        ESTIMATED LAWN SIZE: {lotSize.toLocaleString()} sq ft
      </Typography>

      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="sqft slider"
        defaultValue={value}
        onChange={updateLotSize}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "flex-start",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "#1565C0", color: "white" }}
          onClick={submitLotUpdate}
        >
          Update Lawn Size
        </Button>
        <Typography gutterBottom>{value}% of lot size</Typography>
      </div>
    </div>
  );
}
