export function generateServiceQuestionsSteps(self) {
  const q = [
    {
      name: "",
      type: "mowing",
      steps: [
        {
          id: "service-questions-entry",
          message: `Do you have a locked gate?`,
          trigger: "get-locked-gate",
        },
        {
          id: "ask-notification-before",
          message: `Would you like to be emailed or texted before each visit?`,
          trigger: "ask-notification-before",
        },
        {
          id: "well-thank-you",
          message: `Well thank you for your purchase...`,
          end: true,
        },
      ],
      probing_questions: [
        {
          id: "get-locked-gate",
          options: [
            { value: "Yes", label: "Yes", hideText: true },
            { value: "No", label: "No", hideText: true },
          ],
          optionType: "default",
          trigger: "ask-notification-before",
          setVariable: "lockedGate",
        },
        {
          id: "ask-notification-before",
          options: [
            { value: "Email", label: "Email", hideText: true },
            { value: "Text Message", label: "Text Message", hideText: true },
            { value: "Both", label: "Both", hideText: true },
            {
              value: "Don't Notify Me",
              label: "Don't Notify Me",
              hideText: true,
            },
          ],
          optionType: "default",
          setVariable: "notificationMethod",
          trigger: "well-thank-you",
        },
      ],
    },
  ];

  return q;
}
