import { useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { LocationOn } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
// import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";

import "./autocomplete.css";
import DashboardApi from "../utils/api/dashboardApi";
// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
export function GeoAutoComplete(props) {
  const [value, setValue] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const { triggerNextStep } = props;

  const inputStyle = {
    // maxWidth: 400,
    marginLeft: 10,
    // minWidth:400,
    width: "99%",
    borderRadius: 4,
    boxShadow: "inset 0 0 2px #1565C0",
    borderColor: "#1565C0",
    boxSizing: "border-box",
    color: "#0E283FD9",
    fontSize: 16,
    backgroundColor: "white",

    outline: "none",

    WebkitAppearance: "none",
    // borderWidth: 2,
    // borderStyle: "solid"
    backgroundImage: 'url("/assets/images/mag.png"',
    backgroundRepeat: "no-repeat",
    backgroundSize: inputValue ? "0px" : "30px",
    backgroundPosition: "2% 50%",
  };

  const fetcher = useMemo(
    () =>
      debounce(async (request, callback) => {
        try {
          const response = await DashboardApi.post("/v1/servman/geomap", {
            queryTerm: request.input,
          });

          if (response.status === 200) {
            callback(response.data);
          } else {
            console.log("No matching suggestions found");
          }
        } catch (error) {
          console.log("HERE Maps API error:", error);
        }
      }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetcher({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [...results.items];
        }

        if (results) {
          newOptions = [...newOptions, ...results.items];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetcher]);

  const [isMessage, setIsMessage] = useState(false);

  return !isMessage ? (
    <Autocomplete
      id="sb-auto-complete"
      //sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.address.label
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      fullWidth
      disabled={isDisabled}
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No matches found"
      isOptionEqualToValue={(option, value) => {
        return option.address.label === value;
      }}
      onChange={(event, newValue) => {
        if (!newValue) {
          setValue(null);
          setOptions([]);
          return;
        }
        setOptions(newValue ? [newValue] : options);
        setValue(newValue);

        setIsDisabled(true);
        setIsMessage(true);
        triggerNextStep({
          value: newValue.address,
          sendUserMessage: false,
        });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          // style={{
          //   maxWidth: "400px",
          //   borderColor: "rgb(118,118,118)",
          //   borderWidth: "2px",
          //   borderRadius: "10px"
          // }}
          style={inputStyle}
          {...params}
          fullWidth
          placeholder="       Search address"
        />
      )}
      renderOption={(props, option) => {
        const id = `option_${Math.random()}`;
        return (
          <li {...props} key={id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOn sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Box
                  key={option.id + Math.random()}
                  component="span"
                  sx={{ fontWeight: "regular" }}
                >
                  {option.address.label}
                </Box>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  ) : (
    <div
      className="sc-htoDjs ilpDAI rsc-ts rsc-ts-user"
      style={{ width: "100%" }}
    >
      <div className="sc-gzVnrw jKQObM rsc-ts-image-container" />
      <div
        className="sc-EHOje hDYKxR rsc-ts-bubble"
        style={{
          display: "flex",
          padding: "16px",
          alignSelf: "stretch",
          borderRadius: "16px",
          backgroundColor: "rgb(20, 126, 251)",
          color: "white",
          width: "80%",
          maxWidth: "80%",
          fontSize: "16px",
          lineHeight: "1.2",
          fontFamily: "Inter",
        }}
      >
        {value.address.label}
      </div>
    </div>
  );
}
