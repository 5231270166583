import React, { useState } from "react";
import { invalidInput } from "../common/animations";
import { css } from "styled-components";
import { Mobile, Desktop, Tablet } from "../utils/media-queries";
import { Button } from "@material-ui/core";
import "./inline-user-input.component.css";

export const InlineUserInput = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const inputStyle = {
    animation: props.invalid
      ? css`
          ${invalidInput} .2s ease
        `
      : "",
    // maxWidth: 400,
    marginLeft: 10,
    // minWidth:400,
    width: "100%",
    borderRadius: 4,
    boxShadow: "inset 0 0 2px #E53935",
    boxSizing: "border-box",
    color: "#0E283FD9",
    fontSize: 16,
    opacity: props.disabled && !props.invalid ? ".5" : "1",
    outline: "none",
    padding: props.hasButton ? "16px 52px 16px 10px" : "16px 10px",
    WebkitAppearance: "none",
    borderWidth: 2,
    borderStyle: "solid",
    backgroundImage: 'url("/assets/images/send.svg"',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "98% 50%",
  };

  const validator = (value) => {
    if (props.validator) {
      const val = props.validator(value);
      if (val !== true) {
        setErrorMessage(val);
        return false;
      }
    }
    return true;
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      const validated = validator(inputValue);

      if (!inputValue.length || !validated) return false;
      const { triggerNextStep } = props;
      if (props.dispatchMessage) {
        await props.dispatchMessage(inputValue);
        triggerNextStep({ value: inputValue, sendUserMessage: true });
      } else {
        triggerNextStep({ value: inputValue, sendUserMessage: true });
      }
    }
  };

  const onValueChange = (event) => {
    setInputValue(event.target.value);
  };
  const onBlurSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const validated = validator(inputValue);

    if (!inputValue.length || !validated) return false;
    const { triggerNextStep } = props;
    if (props.dispatchMessage) {
      await props.dispatchMessage(inputValue);
      triggerNextStep({ value: inputValue, sendUserMessage: true });
    } else {
      triggerNextStep({ value: inputValue, sendUserMessage: true });
    }

    return false;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "95vw",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: 5,
          justifyContent: "space-between",
          columnGap: 8,
        }}
      >
        <input
          className="inline-user-input-field"
          name={props.name || ""}
          type={props.type || "textarea"}
          maxLength={props.maxLength}
          value={inputValue}
          placeholder={props.placeholder}
          style={inputStyle}
          onKeyPress={handleKeyPress}
          onChange={onValueChange}
          onBlur={onBlurSubmit}
        />

        {false && props.defaultInputMessage && (
          <Button
            onClick={(e) => {
              setInputValue(props.defaultInputMessage);
              onBlurSubmit(e);
            }}
            variant="text"
            style={{
              color: "#fff",
              textTransform: "none",
              backgroundColor: "#1565C0",
              borderRadius: 4,
              padding: " 8px 22px",

              boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            }}
          >
            {props.defaultInputMessage}
          </Button>
        )}
      </div>
      <em style={{ color: "red", textDecoration: "italic" }}>{errorMessage}</em>
      {/* <em style={{ marginTop: 3 }}>*click enter to continue</em> */}

      <Desktop>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: we don't need this now */}
        <div
          style={{
            width: 47,
            height: 38,
            backgroundColor: "transparent",
            position: "relative",
            float: "right",
            marginLeft: "75%",
            zIndex: 10000000,
            top: -63,
          }}
          onClick={onBlurSubmit}
        />
      </Desktop>
      <Mobile>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: we don't need this now */}
        <div
          style={{
            width: 47,
            height: 38,
            backgroundColor: "transparent",
            position: "relative",
            float: "right",
            marginLeft: "88%",
            zIndex: 10000000,
            top: -63,
          }}
          onClick={onBlurSubmit}
        />
      </Mobile>

      <Tablet>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: we don't need this now */}
        <div
          style={{
            width: 47,
            height: 38,
            backgroundColor: "transparent",
            position: "relative",
            float: "right",
            marginLeft: "70%",
            zIndex: 10000000,
            top: -63,
          }}
          onClick={onBlurSubmit}
        />
      </Tablet>
    </div>
  );
};
