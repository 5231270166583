import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import QuotePage1 from "./pages/page.1.component";
import PaymentForm from "./pages/page.2.component";

import { useMediaQuery } from "react-responsive";
import { Desktop, Mobile, Tablet } from "../../utils/media-queries";
import { ServiceBotHeader } from "../service-bot-header.component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "90%",
    overflow: "hidden",
  },
  desktop: {
    width: 800,
    margin: "auto",
  },
  mobile: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  slim: {
    padding: 0,
    marginTop: 10,
    marginBottom: 15,
  },
  stepIcon: {
    // color: 'rgb(53, 151, 236)',
    // 'completed': {
    //   color: 'rgb(53, 151, 236)',
    // },
    // color: 'pink'
  },
  stepActive: {
    color: "rgb(53, 151, 236)",
  },
}));

function getSteps() {
  return ["PROPOSAL", "CHECKOUT"];
}

function getStepContent(step, props, isDesktop) {
  switch (step) {
    case 0:
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            overflowX: "hidden",
          }}
        >
          <Desktop>
            <div style={{ flex: 1 }} />
          </Desktop>
          <QuotePage1
            {...props}
            key={`dakey${Math.random()}`}
            disableAnimate={isDesktop}
          />
          <Desktop>
            <div style={{ paddingLeft: 10 }}>
              {!props.convertedSale && <PaymentForm {...props} />}
            </div>
          </Desktop>
        </div>
      );
    case 1:
      // return (<PaymentForm />)
      return !props.convertedSale && <PaymentForm {...props} />;
    case 2:
      return "Checkout";
    default:
      return "Unknown step";
  }
}

export default function QuoteStepperHorizontal(props) {
  const classes = useStyles();
  const activeStep = props.currentStep;
  const steps = getSteps();

  const isDesktop = useMediaQuery({ minWidth: 992 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  // const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div
      className={`${classes.root} ${
        isDesktop ? classes.desktop : classes.mobile
      }`}
    >
      <div
        style={{
          paddingRight: 15,
          boxSizing: "content-box",
          overflowY: "scroll",
          width: "100%",
          height: "100%",
        }}
      >
        <Mobile>
          <Stepper activeStep={activeStep} className={classes.slim}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {
                classes: { root: classes.stepIcon, active: classes.activeStep },
              };

              return (
                <Step
                  key={label}
                  {...stepProps}
                  onClick={() => {
                    props.setStep(index);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <StepLabel StepIconProps={labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Mobile>
        <Desktop>
          <ServiceBotHeader {...props} />
        </Desktop>
        <Tablet>
          <Stepper activeStep={activeStep} className={classes.slim}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step
                  key={label}
                  {...stepProps}
                  onClick={() => {
                    props.setStep(index);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Tablet>

        <div>
          {activeStep === steps.length ? (
            <div />
          ) : (
            <div>{getStepContent(activeStep, props, isDesktop)}</div>
          )}
        </div>
      </div>
    </div>
  );
}
