import React, { createRef } from "react";
import { Modal, makeStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    position: "absolute",
    // width: '96vw',
    // maxWidth: 800,
    //   height: (browser === 'firefox' || browser === 'msie') ? '100%' : '-webkit-fill-available',
    // backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    backgroundImage: `url(${process.env.REACT_APP_MEASURIT_URL}/Measureit-logo.png)`,
    /* background-size: 142px; */
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "rgba(	240, 248, 255, 0.7)",
    backgroundBlendMode: "lighten",
    //   padding: theme.spacing(2, 4, 3),
    //   backgroundImage: 'url(/assets/images/city.svg), url(/assets/images/city.svg)',
    //   backgroundRepeat: 'no-repeat, no-repeat',
    //   backgroundSize: '20%, 30%',
    //   backgroundPosition: '2% 10%, 80% 90%'
  },
});

const MeasureItModal = (props) => {
  const frame = createRef();

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const theme = useTheme();

  const isXSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const isXLarge = useMediaQuery(theme.breakpoints.up("lg"));
  let position = {
    left: "2vw",
    minWidth: "95vw",
  };
  if (isXSmall) {
    // console.log("is xtra small");
  } else if (!isXSmall && isSmall) {
    // console.log("is small")
    position = {
      left: "7vw",
      // minWidth: 400
      minWidth: "85vw",
    };
  } else if (!isSmall && isMedium) {
    // console.log("is medium");
    position = {
      left: "7vw",
      // minWidth: 500
      minWidth: "85vw",
    };
  } else if (!isSmall && !isMedium && isLarge) {
    // console.log("is large");
    position = {
      left: "7vw",
      // minWidth: 700
      minWidth: "85vw",
    };
  } else if (!isLarge && isXLarge) {
    // console.log("is extra large");
    position = {
      left: "30vw",
      minWidth: 800,
    };
  }

  function getModalStyle() {
    // const top = 0;
    // const left = 0;

    return {
      position: "fixed",
      top: "6vh", //`${top}%`,
      ...position,

      // transform: `translateX(25%)`,
    };
  }

  const handleOpen = () => {};

  const loadAddress = () => {
    frame.current.contentWindow.postMessage(
      {
        func: "options",
        args: {
          min: 1,
          max: 300000,
          isMetric: props.isMetric,
          mode: props.measurementMode,
          disableBrush: false,
        },
      },
      "*",
    );

    setTimeout(() => {
      frame.current.contentWindow.postMessage(
        {
          func: "setAddressString",
          args: {
            address: props.address,
          },
        },
        "*",
      );
    }, 1000);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.modalOpened}
      onClose={handleOpen}
    >
      <div style={getModalStyle()} className={classes.paper}>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            padding: 5,
          }}
        >
          <iframe
            className="measurit-frame"
            id="measureit-frame"
            onLoad={loadAddress}
            title={"measurit-frame"}
            ref={frame}
            style={{
              width: "100%",
              height: "100%",
              maxWidth: 620,
              minWidth: 315,
              minHeight: 640,
              maxHeight: 820,
              border: "none",
              overflow: "hidden",
              scrollBehavior: "none",
            }}
            src={process.env.REACT_APP_MEASURIT_URL}
            sandbox="allow-popups allow-top-navigation allow-scripts allow-same-origin allow-modals"
          />
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(MeasureItModal);
