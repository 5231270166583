import React from "react";
import { makeStyles } from "@material-ui/core";
import * as _ from "lodash";

import {
  priceCalculations,
  offers as LBOffers,
} from "@workwave_it/lawnbot-pricing-calculations";
import {
  displayMonthPaymentAmount,
  displayTotalAmount,
} from "../../../utils/utils";
import { currencies } from "../../currencies/currencies";

function createRTPPrePayText(services, currency) {
  let text = "";
  const application = services.application.filter((i) => !i.tooLateToDo);
  if (services.application && services.prePay) {
    const applicationTotal = _.reduce(
      application,
      (sum, application) => {
        return sum + application.grossPrice;
      },
      0,
    );
    const prePay = services.prePay.filter((i) => !i.tooLateToDo);
    const prePayTotal = _.reduce(
      prePay,
      (sum, application) => {
        return sum + application.netPrice;
      },
      0,
    );
    const savedAmount = (applicationTotal - prePayTotal).toFixed(2);
    // NOTE: Although savedAmount is a string value
    // this logic works
    if (savedAmount >= 0.01) {
      text += `Save ${currency}${savedAmount} by pre-paying today`;
    } else {
      text = "";
    }
  }
  return text;
}
export default function PriceSummary(props) {
  let currentCurrency = "$";
  const currency = currencies?.find(
    (currency) => currency.name === props.currency,
  );
  if (currency) {
    currentCurrency = currency.symbol;
  }

  let serviceLabel = "SUBTOTAL";

  if (
    props.autoPayPriceMethod === "monthly-payment" &&
    props.term !== "total"
  ) {
    serviceLabel = "FIRST MONTH'S PAYMENT";
  }

  const selectedItems = [
    ...props.priceBlocks.filter((t) => t.selected),
    ...props.availableServices.filter((t) => t.selected),
  ];

  const summary = priceCalculations.getSummaryPricing(
    selectedItems,
    props.term,
    {
      autoPayPriceMethod: props.autoPayPriceMethod,
      autoPayMonthlyPricePeriod: props.autoPayMonthlyPricePeriod || 12,
      serviceTax: props.serviceTax,
      lookupZipCodeTax: props.lookupZipCodeTax,
      taxOverride: props.taxOverride,
      currency: props.currency,
    },
  );

  const useStyles = makeStyles((theme) => ({
    container: {
      background: "#F4F4F4",
      fontFamily: `'Open Sans', sans-serif`,
      fontSize: 13,
      color: "#000000",
      width: "100%",
      Minheight: 133,
      display: "flex",
      flexDirection: "column",
      paddingTop: 25,
      paddingBottom: 25,
    },
    serviceRow: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      paddingTop: 15,
      paddingLeft: 25,
      fontFamily: `'Inter', sans-serif;`,
      fontSize: 16,
      width: "98% !important",
      boxSizing: "border-box",
    },
    serviceItem: {
      textAlign: "flex-start",
      fontFamily: `'Inter', sans-serif;`,
    },
    w49: {
      width: "53%",
    },
    push: {
      paddingLeft: "4rem",
    },
    bolder: {
      fontWeight: "bold",
    },
    discountRow: {
      color: "red",
      fontWeight: "bold",
    },
    mediumPrice: {
      fontSize: 21,
      lineHeight: 1.0,
    },
    bigPrice: {
      fontSize: 32,
      lineHeight: 0.3,
    },
    hidden: {
      display: "none",
    },
  }));

  const classes = useStyles();

  const monthlyprograms = props.priceBlocks.filter((i) => {
    return i.services.application[0].usesUpfrontPricing && i.selected;
  }).length;

  const displayOffers = () => {
    const pboffers = props.priceBlocks
      .filter((i) => i.selected && !i.hideDiscountText)
      .map((i, index) => {
        let offers = [];
        if (i.realTime && i.services) {
          const offerText =
            props.term === "total"
              ? createRTPPrePayText(i.services, currentCurrency)
              : LBOffers.createRTPText(i.services.application, currentCurrency);
          i.offerText = offerText;
          return i;
        }
        if (!i.realTime) {
          offers = i.offers
            .filter((offerItem) => !offerItem.hide)
            .map((offerItem) => {
              const prePayDiscount =
                props.term === "total" &&
                i.prePayDiscount &&
                Number.parseInt(i.prePayDiscount)
                  ? i.prePayDiscount
                  : null;
              offerItem.offerText = `** ${LBOffers.createOfferText(
                i.services.application[0].grossPrice,
                offerItem,
                i.applicationLabel,
                currentCurrency,
                prePayDiscount,
              )}`;
              offerItem.serviceName = i.serviceName;
              offerItem.applicationLabel = i.applicationLabel;
              return offerItem;
            });
        }
        return offers;
      })
      .filter((offerItem) => offerItem.offerText);
    const serviceoffers = props.availableServices
      .filter((i) => i.selected && !i.hideDiscountText)
      .map((i) => {
        let offers = [];
        if (!i.realtime) {
          offers = i.offers
            .filter((o) => !o.hide)
            .map((j) => {
              j.offerText = `${LBOffers.createOfferText(
                i.services.application[0].grossPrice,
                j,
                i.applicationLabel,
                currentCurrency,
              )}`;
              j.serviceName = i.serviceName;
              j.applicationLabel = i.applicationLabel;
              return j;
            });
        }
        return offers;
      });
    let offers = [...pboffers, ...serviceoffers];

    offers = _.flattenDeep(offers);

    if (!offers.length) return null;
    return (
      <div style={{ marginBottom: 10, marginLeft: 5, marginTop: 10 }}>
        <span
          style={{
            fontSize: 12,
            marginLeft: 28,
            textDecoration: "underline",
            fontWeight: 600,
          }}
        >
          Discounts
        </span>
        {offers.map((i) => {
          return (
            <div
              key={i.serviceName}
              className={`${classes.serviceRow}`}
              style={{ paddingTop: 5 }}
            >
              <div
                className={[classes.serviceItem, classes.discountRow].join(",")}
                style={{
                  textAlign: "left",
                  fontSize: 11,
                  fontStyle: "italic",
                  paddingLeft: 30,
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                {i.serviceName} - {i.offerText}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const displayServiceTax = () => {
    if (props.serviceTax === 0.0) return null;

    // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
    if (isNaN(summary.tax)) return null;

    return (
      <>
        <div className={`${classes.serviceRow}`}>
          <div className={`${classes.serviceItem} ${classes.w49}`}>
            {props.currency === "British Pound Sterling"
              ? "VAT  "
              : "SERVICE TAX  "}
            ({props.serviceTax.toFixed(3)}%)
          </div>
          <div className={`${classes.serviceItem} ${classes.push}`}>
            {`${currentCurrency}${summary.tax.toFixed(2)}`}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={classes.container}>
      <div className={`${classes.serviceRow}`}>
        <div className={`${classes.serviceItem} ${classes.w49}`}>
          {serviceLabel}
          {props.autoPayPriceMethod === "monthly-payment" &&
          props.term !== "total" ? (
            <span
              className="note"
              style={{
                color: "blue",
                fontSize: 11,
                marginLeft: 20,
                display: "block",
              }}
            >
              * payments spread over {props.autoPayMonthlyPricePeriod} months.
            </span>
          ) : null}
        </div>
        <div className={`${classes.serviceItem} ${classes.push}`}>
          {currentCurrency}
          {summary.price.toFixed(2)}
        </div>
      </div>
      {displayOffers()}
      {displayServiceTax()}
      <div className={`${classes.serviceRow}`}>
        <div
          className={`${classes.serviceItem} ${classes.bolder} ${classes.w49}`}
        >
          {/* {props.term === "total" ? `TOTAL` : "GET STARTED TODAY FOR"} */}
          GET STARTED TODAY
        </div>
        <div
          className={`${classes.serviceItem} ${classes.bolder} ${classes.push}`}
        >
          {/* total amount */}

          {displayTotalAmount(
            selectedItems,
            props.term,
            {
              autoPayPriceMethod: props.autoPayPriceMethod,
              autoPayMonthlyPricePeriod: props.autoPayMonthlyPricePeriod,
              serviceTax: props.serviceTax,
              lookupZipCodeTax: props.lookupZipCodeTax,
              currency: props.currency,
              taxOverride: props.taxOverride,
            },
            currentCurrency,
          )}
        </div>
      </div>

      {monthlyprograms && props.term !== "total" ? (
        <div className={`${classes.serviceRow}`} style={{ marginTop: 0 }}>
          <div className={`${classes.serviceItem} ${classes.w49}`}>
            {"INSTALLMENT PAYMENT AMOUNT"}
          </div>
          <div className={`${classes.serviceItem} ${classes.push}`}>
            {/* total amount */}

            {displayMonthPaymentAmount(
              selectedItems,
              props.term,
              {
                autoPayPriceMethod: props.autoPayPriceMethod,
                autoPayMonthlyPricePeriod: props.autoPayMonthlyPricePeriod,
                serviceTax: props.serviceTax,
                lookupZipCodeTax: props.lookupZipCodeTax,
                currency: props.currency,
                taxOverride: props.taxOverride,
              },
              currentCurrency,
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
