import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";

import SecureField from "./SecureField";
import SecureFieldIcon from "./SecureFieldIcon";

import "./SecureField.css";

const getUrl = (production) =>
  production
    ? "https://pay.datatrans.com/upp/payment/js/secure-fields-1.0.0.min.js"
    : "https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-1.0.0.min.js";

const initalInputStyle =
  "font-size: 100%; border-radius: 0; -webkit-appearance: none; padding: 0";
const initalCssClass = {
  "secure-field": true,
  "secure-field__input ": true,
  "secure-field__base ": true,
  "secure-field__has-actions ": true,
  "secure-field__has-card-icon": true,
  "secure-field__has-error": false,
  "secure-field__is-recognized": false,
};

class SecureFields extends Component {
  constructor() {
    super();
    this.state = {
      message: null,
      cardIcon: "card-empty",
      cvvIcon: "cvc-empty",
      cardContainerClassNames: initalCssClass,
      cvvContainerClassNames: initalCssClass,
    };
    document.addEventListener("touchstart", {});
  }

  componentDidMount() {
    const scriptSource = getUrl(this.props.production);

    if (document.querySelector(`script[src="${scriptSource}"]`)) {
      this.initSecureFields();
      return;
    }

    const script = document.createElement("script");
    script.src = scriptSource;
    script.onload = () => {
      this.initSecureFields();
    };
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    this.secureFields.destroy();
  }

  render() {
    return (
      <form onSubmit={() => this.secureFields.submit()}>
        <div style={{ maxWidth: "400px" }}>
          {/* <!-- Card Number markup --> */}
          <SecureField
            fieldType="card"
            label=""
            customClass={classnames(this.state.cardContainerClassNames)}
            callback={(e) => {
              e.preventDefault();
              this.secureFields.focus("cardNumber");
              return false;
            }}
          >
            <SecureFieldIcon fieldType="card" iconType={this.state.cardIcon} />
          </SecureField>
        </div>
        <div style={{ width: "100%", marginTop: "20px" }} className="">
          <input
            id="expiry"
            name="expiry"
            placeholder="MM/YY"
            type="tel"
            maxLength="7"
            style={{
              position: "relative",
              float: "left",
              marginLeft: 0,
              height: 17,
              maxWidth: 120,
              marginRight: 15,
              marginTop: 1,
              paddingBottom: 8,
              paddingTop: 13,
            }}
            onChange={(e) => this.setState({ expiry: e.target.value })}
          />

          {/* <!-- CVV markup --> */}
          <SecureField
            fieldType="cvv"
            label=""
            customClass={classnames(this.state.cvvContainerClassNames)}
            callback={(e) => {
              e.preventDefault();
              this.secureFields.focus("cvv");
              // e.preventDefault();
              // e.preventDefault();
              // e.bubbles = false;
              return false;
            }}
          />
        </div>
      </form>
    );
  }

  initSecureFields = () => {
    const { config } = this.props;
    this.secureFields = new window.SecureFields();
    this.secureFields.initTokenize(
      config.merchantID,
      config.fields,
      config.options,
    );
    this.bindSecureFieldsEvents();
    this.setInputFilter(document.getElementById("expiry"), (value) =>
      /^\d{0,2}[\/]?\d{0,2}/.test(value),
    );
  };

  bindSecureFieldsEvents = () => {
    this.secureFields.on("ready", () => {
      // Set styles manually as they're inside an iframe and out of the scope of the parent's stylesheets
      this.secureFields.setStyle("cardNumber", initalInputStyle);
      this.secureFields.setStyle("cvv", initalInputStyle);
      this.secureFields.setPlaceholder("cardNumber", "Card Number");
      this.secureFields.setPlaceholder("cvv", "CVV");
      this.secureFields.focus("cardNumber");
    });

    // Set class names and icon when fields change
    this.secureFields.on("change", (data) => {
      let cardHasFocus = false;
      let cardIsFilled = false;
      let cvvIsFilled = false;

      /* FLOATING LABEL */
      if (data.event.type === "focus") {
        if (data.event.field === "cardNumber") {
          cardHasFocus = true;
        } else {
          cardHasFocus = false;
        }
      }
      if (data.event.type === "blur") {
        cardHasFocus = false;

        if (data.fields.cardNumber.length) {
          cardIsFilled = true;
        } else {
          cardIsFilled = false;
        }

        if (data.fields.cvv.length) {
          cvvIsFilled = true;
        } else {
          cvvIsFilled = false;
        }
      }

      let exp_valid = false;
      let exp = this.state.expiry || "";
      if (exp.length >= 3) {
        exp = exp.replace(/^([0-9]{2})\s*\/*\s*([0-9]*)$/g, "$1 / $2");
      }
      exp_valid = exp.length >= 4;

      const payload = { ...data.fields, exp: { valid: exp_valid, exp } };
      this.props.cardInfo(payload);

      /* FLOATING LABEL END */

      if (data.event.type === "focus") {
        this.props.focusChange(data.event);
      } else {
      }

      const paymentMethod = data.fields.cardNumber.paymentMethod
        ? data.fields.cardNumber.paymentMethod
        : false;

      this.setState((prevState) => ({
        message: null,
        cardContainerClassNames: {
          ...prevState.cardContainerClassNames,
          "secure-field__is-recognized": !!paymentMethod,
          "secure-field__has-error": false,
          "has-focus": cardHasFocus,
          "is-filled": cardIsFilled,
        },
        cvvContainerClassNames: {
          ...prevState.cvvContainerClassNames,
          "secure-field__has-error": false,
          "has-focus": false,
          "is-filled": cvvIsFilled,
        },
        cardIcon: paymentMethod ? `brands/${paymentMethod}` : "card-empty",
        cvvIcon: "cvc-empty",
      }));
    });

    // Set error icon and class name on validate failure
    this.secureFields.on("validate", (data) => {
      if (!data.fields.cardNumber.valid) {
        this.setState((prevState) => ({
          cardContainerClassNames: {
            ...prevState.cardContainerClassNames,
            "secure-field__is-recognized": false,
            "secure-field__has-error": true,
          },
          cardIcon: "card-error",
        }));
      }

      if (!data.fields.cvv.valid) {
        this.setState((prevState) => ({
          cvvContainerClassNames: {
            ...prevState.cvvContainerClassNames,
            "secure-field__has-error": true,
          },
          cvvIcon: "cvc-error",
        }));
      }
    });

    // Show transaction ID on success or transaction error message
    this.secureFields.on("success", (data) => {
      let message = null;
      if (data.transactionId) {
        const { expiry } = this.state;

        this.props.paymentMade({ transactionId: data.transactionId, expiry });
        message = (
          <pre
            className={"form-result success"}
            style={{ marginTop: "20px", fontSize: "1rem" }}
          >
            Data submitted successfully with transaction # {data.transactionId}
          </pre>
        );
      } else if (data.error) {
        message = (
          <pre
            className={`form-result ${data.result}`}
            style={{ marginTop: "20px", fontSize: "1rem" }}
          >
            {data.error}
          </pre>
        );
      }
      this.setState({ message: message });
    });
  };

  setInputFilter(textbox, inputFilter) {
    const self = this;
    // biome-ignore lint/complexity/noForEach: <explanation>
    [
      "input",
      "keydown",
      "keyup",
      "mousedown",
      "mouseup",
      "select",
      "contextmenu",
      "drop",
    ].forEach((key) => {
      textbox.addEventListener(key, function (e) {
        if (/^\d+$/.test(e.key)) {
          this.value = self.formatExpriy(this.value);
        }

        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
  }

  formatExpriy(val) {
    const p1 = Number.parseInt(val[0], 10);
    const p2 = Number.parseInt(val[1], 10);

    return /^\d$/.test(val) && "0" !== val && "1" !== val
      ? `0${val} / `
      : /^\d\d$/.test(val)
        ? p2 > 2 && 0 !== p1
          ? `0${p1} / ${p2}`
          : `${val} / `
        : val;
  }
}

SecureFields.propTypes = {
  production: PropTypes.bool,
  config: PropTypes.object,
  focusChange: PropTypes.func,
  paymentMade: PropTypes.func,
  cardInfo: PropTypes.func,
};

SecureFields.defaultProps = {
  production: false,
  config: {},
};

export default SecureFields;
